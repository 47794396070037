import React from 'react';
import { NativeKit } from '../../nativekit/nativekit';
import { TicTacToe } from '../game';

export default class GameTile extends React.Component<GameTile.Props, GameTile.State> {
    state = { isActive: false };

    componentDidMount() {
    }

    render() {
        return (
            <div style={{
                height: '33%',
                fontSize: 18,
                display: 'flex',
                flex: '1 1 25%',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #000',
                backgroundColor: this.props.tile.highlight ? '#28a745AA' : 'transparent',
            }} onClick={() => {
                NativeKit.Haptic.light();
                this.props.onClick(this.props.tile);
            }}>
                {this.props.tile.player === TicTacToe.PlayerId.Viewer && (
                    <img src="/assets/x.png" style={{
                        height: '10vw',
                    }} />
                )}
                {this.props.tile.player === TicTacToe.PlayerId.Streamer && (
                    <img src="/assets/o.png" style={{
                        height: '10vw',
                    }} />
                )}
                {!this.props.tile.player && this.props.isSelected && this.props.player !== 'o' && (
                    <div style={{ textAlign: 'center' }}>
                        <img src="/assets/x.png" style={{
                            height: '10vw',
                        }} />
                        <div style={{
                            fontSize: 12.0,
                            marginTop: 9.0,
                            color: '#007bff',
                            border: '1px solid #007bff',
                            padding: '2px 4px',
                            borderRadius: 4,
                            opacity: 25 + (75 * (this.props.tile.pctVote / 100))
                        }}>{this.props.tile.pctVote}%</div>
                    </div>
                )}
                {!this.props.tile.player && !this.props.isSelected && this.props.tile.pctVote > 0 && (
                    <div style={{ textAlign: 'center' }}>
                        <img src="/assets/x.png" style={{
                            height: '10vw',
                            opacity: 0.4,
                        }} />
                        <div style={{
                            fontSize: 12.0,
                            marginTop: 9.0,
                            color: '#007bff',
                            border: '1px solid #007bff',
                            padding: '2px 4px',
                            borderRadius: 4,
                            opacity: (25 + (75 * (this.props.tile.pctVote / 100))) / 100,
                        }}>{this.props.tile.pctVote}%</div>
                    </div>
                )}
                {!this.props.tile.player && this.props.isSelected && this.props.player === 'o' && (
                    <img src="/assets/o.png" style={{
                        height: '10vw',
                    }} />
                )}
            </div>
        );
    }
}

export namespace GameTile {
    export interface Props {
        tile: TicTacToe.Tile;
        isSelected: boolean;
        player?: 'x' | 'o';
        onClick: (tile: TicTacToe.Tile) => void;
    }
    export interface State {
    }
}