import React from 'react';
import GameTile from '../../../game/components/GameTile';
import WinOverlay from '../../../game/components/WinOverlay';
import { TicTacToe } from '../../../game/game';
import { HapticButton, NativeKit } from '../../../nativekit/nativekit';

const _kDefaultMsRemaining = 15000;
const _kTimeUpdateIntervalMs = 50;

export default class StreamerView extends React.Component<StreamerView.Props, StreamerView.State> {
    state = {
        msRemaining: _kDefaultMsRemaining,
        selectedTile: null,
    };

    private _decreaseTime() {
        this.setState({
            msRemaining: Math.max(this.state.msRemaining - _kTimeUpdateIntervalMs, 0),
        });
    }

    componentDidMount() {
        TicTacToe.onEndTurn(() => {
            this.setState({
                selectedTile: null,
            });
        });
        TicTacToe.onStateUpdate(() => {
            this.setState({
                msRemaining: TicTacToe.remainingTurnMs,
            });
        });
        setInterval(() => this._decreaseTime(), _kTimeUpdateIntervalMs);
    }

    render() {
        return TicTacToe.status === TicTacToe.Status.Init ? (
            <WinOverlay title="Tic Tac Toe" isVisible hideBackdrop>
                <HapticButton onClick={() => TicTacToe.streamerStartGame()}>
                    Start Game
                </HapticButton>
            </WinOverlay>
        ) : (
            <div style={{
                height: '100vh',
                fontSize: 18,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <WinOverlay
                    isVisible={Boolean(TicTacToe.winner)}
                    title={TicTacToe.winner === TicTacToe.PlayerId.Streamer ? 'You win!' : `Viewers won :(`}>
                    <HapticButton onClick={() => TicTacToe.streamerStartGame()}>
                        Play again
                    </HapticButton>
                </WinOverlay>
                <div style={{
                    width: '90vw',
                    height: '90vw',
                    backgroundColor: '#AAAAAAEE',
                    borderRadius: 18.0,
                    backdropFilter: 'blur(27px)',
                    display: 'flex',
                    flexWrap: 'wrap',
                    overflow: 'hidden',
                }}>
                    {TicTacToe.tiles.map(tile => (
                        <GameTile
                            player='o'
                            tile={tile}
                            onClick={(tile) => {
                                if (TicTacToe.currentPlayer !== TicTacToe.PlayerId.Streamer) return;
                                TicTacToe.streamerPlay(tile);
                                this.setState({
                                    selectedTile: tile,
                                });
                            }}
                            // @ts-ignore
                            isSelected={this.state.selectedTile?.id === tile.id}
                        />
                    ))}
                </div>
                <br />
                <div style={{
                    backgroundColor: '#000000CC',
                    borderRadius: 18.0,
                    width: '75vw',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 16,
                }}>
                    <div style={{
                        fontSize: 22,
                        fontWeight: 500,
                        color: '#fff',
                        marginBottom: 4,
                    }}>
                        {TicTacToe.currentPlayer === TicTacToe.PlayerId.Streamer ?
                            'Your Turn' : 'Viewer Turn'}
                    </div>
                    <div style={{
                        fontSize: 15,
                        fontWeight: 400,
                        color: '#fff',
                    }}>
                        {TicTacToe.currentPlayer === TicTacToe.PlayerId.Streamer ?
                            'tap on a tile to select' : 'waiting for viewers to vote'}
                    </div>
                </div>
                {TicTacToe.currentPlayer === TicTacToe.PlayerId.Viewer && (
                    <div style={{
                        width: `${Math.max(74 * (this.state.msRemaining / _kDefaultMsRemaining), 5)}vw`,
                        height: 16,
                        backgroundColor: '#FF4E6A',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 12.0,
                        fontWeight: 600,
                        color: '#fff',
                    }}>
                        {Math.ceil(this.state.msRemaining / 1000)}s
                    </div>
                )}

            </div>
        );
    }
}

export namespace StreamerView {
    export interface Props {
    }
    export interface State {
        msRemaining: number;
        selectedTile: TicTacToe.Tile | null;
    }
}