const WinOverlay = (props: WinOverlay.Props) => (
    <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1000,
        width: '100vw',
        height: '100vh',
        backgroundColor: props.hideBackdrop ? 'transparent' : '#000000BB',
        backdropFilter: props.hideBackdrop ? 'none' : 'blur(27px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: props.isVisible ? 1 : 0,
        pointerEvents: props.isVisible ? 'initial' : 'none',
    }}>
        <div style={{
            width: '80vw',
            backgroundColor: '#fff',
            borderRadius: 18,
            padding: 27,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            border: '4px solid #000000BB'
        }}>
            <h1>{props.title}</h1>
            {props.children}
        </div>
    </div>
);

namespace WinOverlay {
    export interface Props extends React.PropsWithChildren {
        isVisible: boolean;
        hideBackdrop?: boolean;
        title: string;
    }
}

export default WinOverlay;