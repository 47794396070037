import { io } from 'socket.io-client';

const _socket = io('wss://nativekit-tictactoe-server.herokuapp.com');

export class TicTacToe {
    private static _tiles: TicTacToe.Tile[] = [];
    private static _currentPlayer: TicTacToe.PlayerId;
    private static _winner: TicTacToe.PlayerId;
    private static _status: TicTacToe.Status;
    private static _endTurnTs: number;

    public static _callbacks: (() => void)[] = [];
    public static _endTurnCallbacks: (() => void)[] = [];

    public static vote(tile: TicTacToe.Tile): void {
        _socket.emit('vote', tile.id);
    }

    public static streamerPlay(tile: TicTacToe.Tile): void {
        _socket.emit('streamer-play', tile.id);
    }

    public static streamerStartGame(): void {
        _socket.emit('streamer-new-game', {});
    }

    public static setTiles(tiles: TicTacToe.Tile[]): void {
        this._tiles = tiles;
        this.updateState();
    }

    public static setPlayer(playerId: TicTacToe.PlayerId): void {
        this._currentPlayer = playerId;
        this.updateState();
        this.endTurn();
    }

    public static setWinner(playerId: TicTacToe.PlayerId): void {
        this._winner = playerId;
        this.updateState();
    }

    public static setStatus(status: TicTacToe.Status): void {
        this._status = status;
        this.updateState();
    }

    public static setEndTurnTs(endTurnTs: number): void {
        this._endTurnTs = endTurnTs;
        this.updateState();
    }

    public static get tiles(): TicTacToe.Tile[] {
        return this._tiles;
    }

    public static get status(): TicTacToe.Status {
        return this._status;
    }

    public static get currentPlayer(): TicTacToe.PlayerId {
        return this._currentPlayer;
    }

    public static get winner(): TicTacToe.PlayerId {
        return this._winner;
    }

    public static get remainingTurnMs(): number {
        return Math.max((this._endTurnTs ?? new Date().getTime()) - new Date().getTime(), 0);
    }

    public static onStateUpdate(cb: () => void) {
        this._callbacks.push(cb);
    }

    public static updateState() {
        this._callbacks.forEach(cb => cb());
    }

    public static onEndTurn(cb: () => void) {
        this._endTurnCallbacks.push(cb);
    }

    public static endTurn() {
        this._endTurnCallbacks.forEach(cb => cb());
    }
}

export namespace TicTacToe {
    export enum Status {
        Init = 'init',
        Playing = 'playing',
    }
    export enum PlayerId {
        Viewer = 'viewer',
        Streamer = 'streamer',
    }
    export interface Tile {
        id: number;
        highlight: boolean;
        player: PlayerId;
        pctVote: number;
    }
}

_socket.on('tile-update', tiles => TicTacToe.setTiles(tiles));
_socket.on('switch-player', playerId => TicTacToe.setPlayer(playerId));
_socket.on('set-winner', playerId => TicTacToe.setWinner(playerId));
_socket.on('set-end-turn-ts', endTurnTs => TicTacToe.setEndTurnTs(endTurnTs));
_socket.on('game-status', status => TicTacToe.setStatus(status));